import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { RecordsService } from '../services/records.service';
import { RmsFilterObj } from '../models/rmsFilterObj';
import { RmsService } from '../services/rms.service';
import { Icdiplan } from '../models/icdiplan';
import { Router } from '@angular/router';

declare var swal:any;

@Component({
  selector: 'app-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.css']
})
export class MapSearchComponent implements OnInit {
  resultset:any[] = [];
  planSetID:string = "";
  mapRef:string;
  sharedSetTitle:string = "";
  sharedSetYear:string = "";
  isSEdit:boolean = false;
  isSavingSet:boolean = false;
  isNoResult:boolean = false;
  isResult: boolean;
  isSearched: boolean;
  isSearching: boolean;
  accessLevel:number = 1;
  isRequestFromMap: boolean;
  constructor(
    private _rmsService:RecordsService, 
    private _serv:RmsService,
    private _router: Router
    ) { }

  ngOnInit() {
    let url = this._router.url;
    var url_part = url.split("/");
    this.mapRef = url_part[url_part.length -1];

    this._serv.AccessLevel.subscribe(al =>{
      this.accessLevel = al;
    });
//    console.log(this.mapRef);
    if(this.mapRef){
      this._rmsService.loadPlansBySet(this.mapRef).subscribe((res: Array<Icdiplan>) => {
        this.resultset = res;
        if (this.resultset.length > 0) {
          this.sharedSetTitle = res[0].PlanTitle;
          this.sharedSetYear = res[0].PlanYear;
          this.planSetID = res[0].SetUID;
        }
        // update service data store
        this._rmsService.changeMapDataSource(res);
        this._rmsService.changeMapDataSourceFilter(res);
      });
    }
  }
 
  activateSetEdit():void{
    this.isSEdit = !this.isSEdit;
  }
 

  goToPreviousPage(){
    //this._location.back();
  }

  saveSetEditTitle():void{
    this.isSavingSet = true;
    let cleanInputTitle = this.sharedSetTitle.replace(/[^\w\s]/gi, '');
    let cleaYear = this.sharedSetYear.trim();
    this._rmsService.saveSetTitleAndYear(this.mapRef, cleanInputTitle, cleaYear).subscribe(res =>{
      this.isSavingSet = false;
      this.isSEdit = false;
      if(res)
        this.reloadPlanSet(this.mapRef);
    })
    
  }

  reloadPlanSet(filename){
    this._rmsService.loadPlansBySet(filename).subscribe(res =>{
      this.resultset = res;
      // update service data store
      this._rmsService.changeMapDataSource(res);
      this._rmsService.changeMapDataSourceFilter(res);
      swal("Set updated successfully");
     });
  }
}
