import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { PlanArchiveComponent } from './plan-archive/plan-archive.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';

import { SearchComponent } from './search/search.component';


import { AuthguardService } from './services/authguard.service';
import { MapSearchComponent } from './map-search/map-search.component';
import { ResultsComponent } from './results/results.component';
import { ListViewComponent } from './list-view/list-view.component';
import { TableViewComponent } from './table-view/table-view.component';
import { DetailViewComponent } from './detail-view/detail-view.component';
import { ResultMapComponent } from './map-result/result-map/result-map.component';
import { MapListViewComponent } from './map-result/map-list-view/map-list-view.component';
import { MapTableViewComponent } from './map-result/map-table-view/map-table-view.component';
import { MapDetailViewComponent } from './map-result/map-detail-view/map-detail-view.component';
import { SettingsComponent } from './settings/settings.component';
import { LogsComponent } from './logs/logs.component';


export const router: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full'},
    { path: 'home', component: HomeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'about', component: AboutComponent },
    { path: 'log', component: LogsComponent },
    { path: 'settings', component: SettingsComponent},
    { path: 'advanced', component: AdvancedSearchComponent, canActivate: [AuthguardService], children: [
        {path: 'result', component: ResultsComponent, children: [
            {path: '', redirectTo: 'list', pathMatch: 'full'},
            {path: 'list', component: ListViewComponent},
            {path: 'tbl', component: TableViewComponent},
            {path: 'detail', component: DetailViewComponent}
        ]}
    ] },
    { path: 'archive', component: PlanArchiveComponent, canActivate: [AuthguardService] },
    { path: 'search', component: SearchComponent, canActivate: [AuthguardService] },
    { path: 'mapref', component: MapSearchComponent, canActivate: [AuthguardService], children: [
        {path: 'result-map', component: ResultMapComponent, children: [
            {path: '', redirectTo: 'detail', pathMatch: 'full'},
            {path: 'list/:fileName', component: MapListViewComponent},
            {path: 'tbl/:fileName', component: MapTableViewComponent},
            {path: 'detail/:fileName', component: MapDetailViewComponent}
        ]}
    ]}
];

export const routes: ModuleWithProviders = RouterModule.forRoot(router);
