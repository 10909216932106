import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bobFilter'
})
export class BobFilterPipe implements PipeTransform {
  transform(plans: any): any {
    if(plans !=="No records found"){
      var __filter__dom = document.getElementsByName('inlineRadioOptions');
      var __display_count_text_dom = document.getElementById('filter-display-count');
      if(__filter__dom.length > 0){
        var btnsArr = Array.prototype.slice.call(__filter__dom);
        const __checked__item = btnsArr.filter(i => i.checked);
        switch (__checked__item[0].value.toLowerCase()){
          case 'sanitary':
              plans = plans.filter(p => p.HasSanitary == 1);
            break;
          case 'water':
              plans = plans.filter(p => p.HasWater == 1);
            break;
          case 'storm':
              plans = plans.filter(p => p.HasStorm == 1);
            break;
          case 'swmp':
              plans = plans.filter(p => p.HasSWMP == 1);
            break;
          case 'bridge':
              plans = plans.filter(p => p.HasBridge == 1);
            break;
          default:
            break;
        }
      }
      if(__display_count_text_dom){
        __display_count_text_dom.innerText = `${plans.length}`;
      }
      return plans;
    }
  }

}
