import { Pipe, PipeTransform } from '@angular/core';
import { RmsService } from './services/rms.service';

declare var $:any;

@Pipe({
  name: 'utilityFilter'
})
export class UtilityFilterPipe implements PipeTransform {
  filteredPlansArray:Array<any> = [];
  constructor(private _rmsService:RmsService){}
  transform(plans: any, args: any): any {
      if(plans !=="No records found"){
        let filterType = args[0], filteredCount = args[1];
        if(filterType == "all"){
          filteredCount.count = plans.length;
          this._rmsService.searchResutlsSharedAdvance.resultArray = plans;
          return plans;
        }
        if(filterType == "sanitary"){
          var result = plans.filter((plan) => plan.HasSanitary == 1);
          filteredCount.count = result.length;
          this._rmsService.searchResutlsSharedAdvance.resultArray = result;
          return result;
        }
    
         if(filterType == "water"){
           var result = plans.filter((plan) => plan.HasWater == 1);
           filteredCount.count = result.length;
           this._rmsService.searchResutlsSharedAdvance.resultArray = result;
           return result;
        }
    
        if(filterType == "storm"){
         var result = plans.filter((plan) => plan.HasStorm == 1);
         filteredCount.count = result.length;
         this._rmsService.searchResutlsSharedAdvance.resultArray = result;
         return result;
        }
   
        if(filterType == "swmp"){
         var result = plans.filter((plan) => plan.HasSWMP == 1);
         filteredCount.count = result.length;
         this._rmsService.searchResutlsSharedAdvance.resultArray = result;
         return result;
       }
       if(filterType == "bridge"){
        var result = plans.filter((plan) => plan.HasBridge == 1);
        filteredCount.count = result.length;
        this._rmsService.searchResutlsSharedAdvance.resultArray = result;
        return result;
      }
        
      }
  }
}
