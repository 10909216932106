import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-results-navbar',
  templateUrl: './results-navbar.component.html',
  styleUrls: ['./results-navbar.component.css']
})
export class ResultsNavbarComponent implements OnInit {
  planType:string = "all";
  constructor() { }

  ngOnInit() {
  }
  updatePlanType(plantype:string){
    switch (plantype) {
        case "sanitary":
          this.planType = "sanitary";
          break;
        case "water":
          this.planType = "water";
        break;
        case "storm":
          this.planType = "storm";
        break;
        case "site":
        this.planType = "site";
      break;
        default:
          this.planType = "all";
          break;
    }
  }

}
