import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var swal:any;
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  userPreferences: any = {
    fileNameOrder: false,
    explicitSearch:false
  };
  constructor(private _router: Router) { }

  ngOnInit() {
    var __user_settings_storage = localStorage.getItem("__userSettings");
    if(__user_settings_storage){
      var __current_settings = JSON.parse(__user_settings_storage);
      this.userPreferences.fileNameOrder = __current_settings.fileNameOrder;
      this.userPreferences.explicitSearch = __current_settings.explicitSearch;
    }
  }

  saveSettings(){
    var __user_settings_storage = localStorage.getItem("__userSettings");
    if(__user_settings_storage){
      // remove
      localStorage.removeItem("__userSettings");
    }
    // store new one
    localStorage.setItem("__userSettings", JSON.stringify(this.userPreferences));
    
    swal({
      text: "Your preferences have been saved.",
      closeOnClickOutside: false,
      buttons: {cancel:false, confirm:"OK"},
      dangerMode: true
    }).then(action =>{
      if(action){
        // redirect to search view
        this._router.navigate(['/advanced/']);
      }
    })
  }
  cancelSettings(){
    swal({
      text: "Your changes will not be saved.",
      closeOnClickOutside: false,
      buttons: true,
      dangerMode: true
    }).then(action =>{
      if(action){
        // redirect to search view
        this._router.navigate(['/advanced/']);
      }
    })
  }
}
