import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bobOrder'
})
export class BobOrderPipe implements PipeTransform {

  transform(plans: any, args?: any): any {
    let __plans = plans;
    switch (args.name){
      case 'name':
          if(args.orderby === 'fas fa-sort-up'){
            __plans = plans.sort((a,b) => (a.FileName > b.FileName) ? 1 : -1);
          }
          else{
            __plans = plans.sort((a,b) => (a.FileName < b.FileName) ? 1 : -1);
          }
        break;
      case 'title':
          if(args.orderby === 'fas fa-sort-up'){
            __plans = plans.sort((a, b) => (a.PlanTitle > b.PlanTitle) ? 1 : -1);
          }
          else{
            __plans = plans.sort((a, b) => (b.PlanTitle < a.PlanTitle) ? 1 : -1);
          }
        break;
      case 'year':
        if(args.orderby === 'fas fa-sort-up'){
          __plans = plans.sort((a, b) => a.PlanYear - b.PlanYear);
        }
        else{
          __plans = plans.sort((a, b) => b.PlanYear - a.PlanYear);
        }
        break;
    }
    return __plans;
  }

  compare_to_sort(x,y)
  {
    if (x.PlanTitle < y.PlanTitle)
      return -1;
    if (x.PlanTitle > y.PlanTitle)
      return 1;
    return 0;
  }
}
