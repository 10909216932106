import { Component, OnInit } from '@angular/core';
import { Icdiplan } from '../../models/icdiplan';
import { RecordsService } from '../../services/records.service';
import { RmsService } from '../../services/rms.service';
import { ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';
import 'datatables.net'
declare var swal:any;

@Component({
  selector: 'app-map-table-view',
  templateUrl: './map-table-view.component.html',
  styleUrls: ['./map-table-view.component.css']
})
export class MapTableViewComponent implements OnInit {
  resultSet:Array<Icdiplan>;
  maprefNber: any;
  accessLevel:number = 1;
  isResultSet: boolean = false;
  isLoading: boolean;
  resultSetArray:Array<any> = [];
  public tableWidget: any;

    // user preferences
    isFileNameOrder: boolean = false;

  constructor(
    private _service: RecordsService,
    private _rms_service: RmsService,
    private _activatedroute: ActivatedRoute
  ) { }

  ngOnInit() {
    // check file name order preference
    var __user_settings_storage = localStorage.getItem("__userSettings");
    if(__user_settings_storage){
      var __current_settings = JSON.parse(__user_settings_storage);
      this.isFileNameOrder = __current_settings.fileNameOrder;
    }

    this._rms_service.AccessLevel.subscribe(al =>{
      this.accessLevel = al;
    });
    this.loadPlanSet();
    if(this.resultSet.length > 0) {
      this.isResultSet = true;
    }else{this.isResultSet = false;}
  }

  loadPlanSet(){
    this._service.currentDataSourceFilteredFromMap.subscribe(res => {
      this.resultSet = res;
      this.resultSetArray = res;
      if(this.resultSetArray.length > 0) {
        this.isResultSet = true;
      }else{this.isResultSet = false;}
      this.reInitDatatable();
    });
  }

  checkAccessLevel(){
    this._rms_service.AccessLevel.subscribe(al =>{
      this.accessLevel = al;
    });
  }

  deletePlan(filename:string, domEl):void{
    swal({
      title: 'Are you sure?',
      text: `You will not be able to recover this file - ${filename}.pdf`,
      buttons: true,
      dangerMode: true,
    }).then(action =>{
      if(action){
        //delete Plan
        // get the logged in user email
      var __email = document.getElementById("logged-in-user-id").innerText;
        this._service.deleteSheet(filename, __email).subscribe(res =>{
          if(res){
            for(var i = 0; i < this.resultSet.length; i++){
              if(this.resultSet[i].FileName == filename){
                this.resultSet.splice(i, 1);
                //this.filteredCount.count --;
              }
            }
          }
        })
  
      }
    })
  }

  initDatatable(){
    let exampleId: any = $('#tblSearchResults');
    if(this.isFileNameOrder){
      this.tableWidget = exampleId.DataTable({
        retrieve: true,
        pageLength: 100,
        columnDefs: [
          {
            targets:[1,8,9,10,11,12],
            orderable: false
          }
        ],
        order:[[2, "asc"]]
      });
    }else{
      this.tableWidget = exampleId.DataTable({
        retrieve: true,
        pageLength: 100,
        columnDefs: [
          {
            targets:[1,8,9,10,11,12],
            orderable: false
          }
        ]
      });
    }
  }

  private reInitDatatable(): void {
    if ($.fn.dataTable.isDataTable('#tblSearchResults')) {
      this.tableWidget.destroy()
      this.tableWidget=null
    }
    setTimeout(() => this.initDatatable(),100)
  }

}
