import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';



import { AuthenticationService } from './authentication.service';
import { RmsService } from './rms.service';

@Injectable()
export class AuthguardService implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean { // Observable<boolean> | Promise<boolean> |
      // tslint:disable-next-line: curly
      return true;  // DMM 10/11/2022 Security
      if (localStorage.getItem('user_token'))
      return true;
      this.router.navigate(['/login']);
      return false;
  }
}




