import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Icdiplan } from '../models/icdiplan';
import * as $ from 'jquery';
import 'datatables.net'
import { RecordsService } from '../services/records.service';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.css']
})
export class TableViewComponent implements OnInit, AfterViewInit {
  isResultSet: boolean = false;
  isLoading: boolean;
  isImagePreview: boolean = false;
  noThumbnail: string = '../../assets/img/no-image-available.png';
  resultSet: Object;
  resultSetArray: Array<any> = [];
  public tableWidget: any;

  // user preferences
  isFileNameOrder: boolean = false;

  constructor(
    private _recordService: RecordsService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {

    // check file name order preference
    var __user_settings_storage = localStorage.getItem("__userSettings");
    if(__user_settings_storage){
      var __current_settings = JSON.parse(__user_settings_storage);
      this.isFileNameOrder = __current_settings.fileNameOrder;
    }

    this._recordService.currentDataSourceFiltered.subscribe(res =>{
      this.resultSet = res;
      this.resultSetArray = res;
      if(this.resultSetArray.length > 0) {
        this.isResultSet = true;
      }else{this.isResultSet = false;}
      this.reInitDatatable();
    })
  }

  ngAfterViewInit(){
    //this.reInitDatatable();
  }

  initDatatable(){
    let exampleId: any = $('#tblSearchResults');

    if(this.isFileNameOrder){
      this.tableWidget = exampleId.DataTable({
        retrieve: true,
        pageLength: 100,
        columnDefs: [
          {
            targets:[7,8,9,10,11],
            orderable: false
          }
        ],
        order:[[1, "asc"]]
      });
    }else{
      this.tableWidget = exampleId.DataTable({
        retrieve: true,
        pageLength: 100,
        columnDefs: [
          {
            targets:[7,8,9,10,11],
            orderable: false
          }
        ]
      });
    }
  }

  private reInitDatatable(): void {
    if ($.fn.dataTable.isDataTable('#tblSearchResults')) {
      this.tableWidget.destroy()
      this.tableWidget=null
    }
    setTimeout(() => this.initDatatable(),100)
  }

  showThumbnailPreview(){
    // preview plan thumbnail
    this.isImagePreview = true;
  }

  hideThumbnailPreview(){
    // remove plan thumbnail preview
    this.isImagePreview = false;
  }

}

