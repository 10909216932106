import { Injectable } from '@angular/core';
import { GalleryPlan } from '../models/rmsUserObj';

@Injectable()
export class AuthenticationService {
  user:GalleryPlan;
  constructor() { }

  signIn(email:string, pw:string){

  }

  getUser(){
    return this.user;
  }
}
