
import {tap, catchError} from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor{
    constructor(private _router:Router){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //if(req.headers.get('No-Auth') == "True")
           // return next.handle(req.clone());
        if(localStorage.getItem("user_token")){
            const clonedreq = req.clone({
                headers:req.headers.set("Authorization", "Bearer " + localStorage.getItem("user_token"))
            });
            return next.handle(clonedreq).pipe(
                tap(
                    succ =>{},
                    err =>{
                        console.log(err);
                        // debugger;
                        
                        if(err.status === 401)
                            this._router.navigate(['/login']);
                    }
                ))
        }
        else{
            this._router.navigate(['/login']);
        }

    }
}
