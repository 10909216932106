import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rmsOrder'
})
export class RmsOrderPipe implements PipeTransform {

  transform(plans: any, args?: any): any {
    var __user_settings_storage = localStorage.getItem("__userSettings");
    if(__user_settings_storage){
      var __current_settings = JSON.parse(__user_settings_storage);
      if(__current_settings.fileNameOrder){
        // return sorted array by file name
        return plans.sort(this.compare);
      }
      else{
        // return same array
        return plans;
      }
    }
    else{
      return plans;
    }
  }

  compare(a, b) {
    const genreA = a.FileName.toUpperCase();
    const genreB = b.FileName.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }
}
