import { Component, OnInit } from '@angular/core';
import { RecordsService } from "../services/records.service";
import { HttpEventType } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from "rxjs";
// import * as $ from 'jquery';
// import 'datatables.net'
//import swal from 'sweetalert';

declare var $:any;
declare var swal:any;

@Component({
  selector: 'app-plan-archive',
  templateUrl: './plan-archive.component.html',
  styleUrls: ['./plan-archive.component.css']
})
export class PlanArchiveComponent implements OnInit {
  // default values params
  colStreetName:string = "";
  planTitle:string = "";
  planNewFolder:string = "";
  planYear:string = "";
  maxSetUID:string;
  totalSize:number = 0;

  planCustomName = "";
  sheetType = "0";
  sheetYear = new Date().getFullYear();
  sheetNumber = "1"; 
  sheetStreets = "";
  hasSanitary = "NO";
  hasStorm = "NO";
  hasWater = "NO";
  hasSWMP = "NO";
  hasBridge = "NO";
  sizeSort:string = "fa fa-sort";
  sortType:string = "asc";

  isNewFolder:boolean = false;
  isFilesDroped:boolean = false;
  isProcessingFiles: boolean = false;
  isArchiving = false;
  planFolder:string;
  getTotalSize:string;
  isAllColumn:boolean = false;

  
  //FilesArray
  filesError:any = [];
  filesToBeUploaded: any = [];
  PlansSheetTypeList:any = [];
  PlansFolderList:any = [];

  listFiles:any = [];
  uploading:boolean = false;
  _fm_filesOnServer:boolean = false;
  filesOnServer = [];

  progress: number;

  tableWidget: any = null;
  sheetNumberIncrement: number;

  constructor(private _rmsService:RecordsService) { }

  ngOnInit() {
    this.loadCustomInfo();
    this.initFilesDropZone();
    $('.toast').toast('hide');
    this.sheetNumberIncrement = 1;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  initDatatable(){
    try {
        this.tableWidget = $('#tblPlanUpload').DataTable({
            pageLength: 100,
            paging: false,
            searching: false,
            columnDefs: [
                //{ type: "file-size", targets: 1 },
                { orderable: true, className: 'reorder', targets: [0, 3] },
                { orderable: false, targets: '_all' }
            ],
            dom: 'l,Bfrtip'
        });

        if (
            this.listFiles.length === 0 ||
            !$("#tblPlanUpload tr").hasClass('bg-warning')
            ) {
            // this.reset();
            $('.toast').toast('hide');
        }
    } catch (error) {
        
    }
  }

  reInitDatatable(): void {
    if ($.fn.dataTable.isDataTable('#tblPlanUpload')) {
      this.tableWidget.destroy()
      this.tableWidget=null
    }
    setTimeout(() => this.initDatatable(),100)
  }

  loadCustomInfo(){
    this._rmsService.getCustomGeneralServerInfo().subscribe((res) =>{
        // assign custom server info
        this.maxSetUID = res.maxSetUID;
        this.PlansFolderList = res.listFolders;
        this.PlansSheetTypeList = res.listPlansType;
        this.PlansSheetTypeList.push({UID: 0, SheetType: 'Select Sheet Type'});
    })
  }

  sortPlans(){
      if(this.sortType == "asc"){
        this.sizeSort = "fa fa-sort-asc";
        this.sortType = "desc";
        return this.filesToBeUploaded.sort((a,b)=> a.size - b.size);
      }
      else{
          this.sortType = "asc";
          this.sizeSort = "fa fa-sort-desc";
          return this.filesToBeUploaded.sort((a,b)=> b.size - a.size);
        } 
  }

  sort(){
    return this.filesToBeUploaded.sort((d1, d2) =>{
        if(d1.size > d2.size){
          this.sizeSort = "fa fa-sort-desc";
          return 1;
        }
        else{
          this.sizeSort = "fa fa-sort-asc";
          return -1;
        }
    })
  }

  reset() {
    this.sheetNumberIncrement = 1;
    this.filesToBeUploaded = [];
    this.listFiles = [];
    this.uploading = false;
    this.filesError = [];
    this.loadCustomInfo();
}
  updateUniqueValue(evt, planName, domElmt) {
      //console.log(domElmt);
      var that = this;
      let val = evt.target.value.trim();
    for (var i = 0; i < this.filesToBeUploaded.length; i++) {
        if(this.isAllColumn){
            switch (domElmt) {
                case 'setid':
                    this.filesToBeUploaded[i].SetUID = this.maxSetUID;
                    break;
                case 'planCustomName':
                    this.filesToBeUploaded[i].PlanName = val;
                    break;
                case 'sheetType':
                        try{
                            let elmts = $("select[name='bulk-sheet-type']");
                            for(var j = 0; j < elmts.length; j++){
                                $(elmts[j]).val(val);    
                            }
                            this.filesToBeUploaded[i].SheetType = val;
                        }
                        catch(e){
                            //console.log(e);
                        }
                    break;
                case "sheetYear":
                    this.filesToBeUploaded[i].PlanYear = this.sheetYear;
                    break;
                case 'sheetNber':
                    this.filesToBeUploaded[i].SheetNumber = val;
                    break;
                case 'sheetStreets': 
                    this.sheetStreets = val;                 
                    this.filesToBeUploaded[i].SheetStreets = val;
                    break;
                case 'hasSanitary':
                        try{
                            let elmts = $("select[name='bulk-san']");
                            for(var j = 0; j < elmts.length; j++){
                                $(elmts[j]).val(val);    
                            }
                            this.filesToBeUploaded[i].HasSanitary = val;
                        }
                        catch(e){
                            //console.log(e);
                        }
                    break;
                case 'hasStorm':
                        try{
                            let elmts = $("select[name='bulk-storm']");
                            for(var j = 0; j < elmts.length; j++){
                                $(elmts[j]).val(val);    
                            }
                            this.filesToBeUploaded[i].HasStorm = val;
                        }
                        catch(e){
                            //console.log(e);
                        }
                    break;
                case 'hasWater':
                        try{
                            let elmts = $("select[name='bulk-water']");
                            for(var j = 0; j < elmts.length; j++){
                                $(elmts[j]).val(val);    
                            }
                            this.filesToBeUploaded[i].HasWater = val;
                        }
                        catch(e){
                            //console.log(e);
                        }
                    
                    break;
                case 'hasSWMP':
                        try{
                            let elmts = $("select[name='bulk-str']");
                            for(var j = 0; j < elmts.length; j++){
                                $(elmts[j]).val(val);    
                            }
                            this.filesToBeUploaded[i].HasSWMP = val;
                        }
                        catch(e){
                            //console.log(e);
                        }
                    break;
                case 'hasBridge':
                    try{
                        let elmts = $("select[name='bulk-site']");
                        for(var j = 0; j < elmts.length; j++){
                            $(elmts[j]).val(val);    
                        }
                        this.filesToBeUploaded[i].HasBridge = val;
                    }
                    catch(e){
                        //console.log(e);
                    }

                break;
            }
        }
        else{
            if (this.filesToBeUploaded[i].FileName == planName) {
                switch (domElmt) {
                    case 'setid':
                        this.filesToBeUploaded[i].SetUID = val;
                        break;
                    case 'planCustomName':
                        this.filesToBeUploaded[i].PlanName = val;
                        break;
                    case 'sheetType':
                        this.filesToBeUploaded[i].SheetType = val;
                    case "sheetYear":
                        this.filesToBeUploaded[i].PlanYear = val;
                        break;
                    case 'sheetNber':
                        this.filesToBeUploaded[i].SheetNumber = val;
                        break;
                    case 'sheetStreets':
                        this.filesToBeUploaded[i].SheetStreets = val;
                        break;
                    case 'hasSanitary':                      
                        this.filesToBeUploaded[i].HasSanitary = val;
                        break;
                    case 'hasStorm':
                        this.filesToBeUploaded[i].HasStorm = val;
                        break;
                    case 'hasWater':                      
                        this.filesToBeUploaded[i].HasWater = val;
                        break;
                    case 'hasSWMP':
                        this.filesToBeUploaded[i].HasSWMP = val;
                        break;
                    case 'hasBridge':
                        this.filesToBeUploaded[i].HasBridge = val;
                        break;
                }
            }
        }
    }
}

  initFilesDropZone(){
    var that = this;
    let dropZone = document.getElementById('drop-zone');

    dropZone.ondrop = function (e) {
     // $scope.show = true;
     that.isProcessingFiles = true;
      e.preventDefault();
      (e.target as HTMLElement).className = 'upload-drop-zone';
      that.processFiles(e.dataTransfer.files).then((res) =>{
        that.isProcessingFiles = false;
        that.reInitDatatable();
        setTimeout(ev => {
            if(that.filesOnServer.length>0){
                // alert user for existing files
                that._fm_filesOnServer = true;
                setTimeout(() => {
                    $('.toast').toast({
                        autohide: false,
                        delay: 6000
                    });
                    $('.toast').toast('show');
    
                    $('.toast').on('hidden.bs.toast', function () {
                        that._fm_filesOnServer = false;
                    });
                }, 20);
            }
            else
                that._fm_filesOnServer = false;
        }, 1000);

        // setTimeout(evt=>{that._fm_filesOnServer = false; that.filesOnServer = [];},5000);
        
      });
  }

  dropZone.ondragover = function (e) {
      (e.target as HTMLElement).className = 'upload-drop-zone drop';
      return false;
  }
    dropZone.ondragleave = function (e) {
        //that.className = 'upload-drop-zone';
        (e.target as HTMLElement).className = 'upload-drop-zone';
        return false;
    }
  }

  isFileOnServer(istru:boolean){
      //console.log(istru);
      return istru ? "bg-warning": "";
  }

  processFiles(files){
     // console.log(files);
    var that = this;
      let _promise = new Promise((resolve, reject) =>{
        if (files.length > 0) {
            let timer = files.length < 50 ? 10 : 3000;
            setTimeout(function(){
                $.each(files, function(i, file){
                    //  console.log(i);
                    if (file.type == "application/pdf") {
                        that.totalSize = that.totalSize + file.size;
                       // console.log(that.checkFileOnServer(file.name.split(".pdf")[0]));

                        that._rmsService.checkFileOnServer(file.name.split(".pdf")[0]).subscribe(res =>{ 
                            if(res) that.filesOnServer.push(file.name);
                            that.filesToBeUploaded.push({
                                isPresentOnServer: res,
                                ConstructionPlan: file,
                                PlanTitle: null,
                                FileSource: null,
                                PlanYear: that.sheetYear,
                                SetUID: that.maxSetUID,
                                PlanName:file.name.split(".pdf")[0],
                                FileName:file.name,
                                FileExt:file.name.split(".pdf")[1],
                                size: file.size,
                                sizeinunit: that.getFileSize(file.size),
                                SheetType:that.sheetType,
                                SheetNumber: (that.sheetNumberIncrement++), 
                                SheetStreets:that.sheetStreets,
                                HasSanitary:that.hasSanitary,
                                HasStorm:that.hasStorm,
                                HasWater:that.hasWater,
                                HasSWMP:that.hasSWMP,
                                HasBridge:that.hasBridge
                            });
                        });
                    }
                  })
                  that.listFiles = that.filesToBeUploaded;
                  that.getTotalSize = that.getFileSize(that.totalSize);
                  if(that.filesToBeUploaded.length > 0){
                    that.isFilesDroped = true;
                  }
                resolve("OK");
            }, timer);
        }
      });
      return _promise;  
  }

  showWarningMessage() {
    
  }

  changePlanFolder = function () {
    if (this.planFolder == "Create New Folder")
        this.isNewFolder = true;
    else
        this.isNewFolder = false;
}

  getFileSize(size) {
    if (isNaN(size))
        size = 0;

    if (size < 1024)
        return size + ' Bytes';

    size /= 1024;

    if (size < 1024)
        return size.toFixed(2) + ' KB';

    size /= 1024;

    if (size < 1024)
        return size.toFixed(2) + ' MB';

    size /= 1024;

    if (size < 1024)
        return size.toFixed(2) + ' GB';

    size /= 1024;

    return size.toFixed(2) + ' TB';
}

  removeFile(index, size) {
    this.listFiles.splice(index, 1);
    this.totalSize = this.totalSize - size;
    this.getTotalSize = this.getFileSize(this.totalSize);
    this.reInitDatatable();
    if(this.listFiles.length < 1) {
        this.sheetNumberIncrement = 1;
    }
}

checkSheetType(){
    let _invalid_type = [];

    this.filesToBeUploaded.forEach(sheet =>{
        if(sheet.SheetType == "0")
            _invalid_type.push(sheet);
    });

    let _types = {
        isValid: _invalid_type.length > 0,
        invalid_items: _invalid_type
    };

    return _types;
}

archivePlans() {
    var that = this;
    if (this.planTitle != "" && this.planFolder !="" && this.planYear !="") {
        //check for sheet type in each plan
        if(!this.checkSheetType().isValid){
            that.isArchiving = true;
            $("#btn-archive").prop("disable", true);
            var _planFolder:string;
            for (var i = 0; i < this.filesToBeUploaded.length; i++) {
                _planFolder = this.planFolder == 'Create New Folder' ? this.planNewFolder : this.planFolder;
                this.filesToBeUploaded[i].PlanTitle = this.planTitle;
                this.filesToBeUploaded[i].FileSource = this.planFolder == 'Create New Folder' ? this.planNewFolder.toUpperCase() : this.planFolder;
                this.filesToBeUploaded[i].planYear = this.planYear;
            }
            this._rmsService.archivePlanSetList(this.filesToBeUploaded)
            .pipe(
                map((event: any) => {
                    // console.log(event);
                  if (
                      event.type == HttpEventType.UploadProgress ||
                      event.type == 1 
                      ) {
                    this.progress = Math.round((100 / event.total) * event.loaded);
                  } else if (
                      event.type == HttpEventType.Response ||
                      event.type == 4
                      ) {
                        this.progress = 100;
                        if(event.status === 200) {
                            that.isArchiving = false;
                            that.reset();
                            swal('Plan(s) uploaded successfully');
                        }
                  }
                }),
                catchError((err: any) => {
                  console.log(err);
                  this.progress = 0;
                  that.isArchiving = false;
                  swal('We were unable to upload these files. Please try again later');
                  return throwError(err.message);
                })
              )
              .toPromise();


            /* .subscribe(function (res) {
                //console.log(res);
                that.isArchiving = false;
                $("#btn-archive").prop("disable", false);
                switch (res) {
                    case "OK":
                        that.reset();
                        swal('Plan(s) uploaded successfully');
                        break;
                    default:
                        swal('We were unable to upload these files. Please try again later');
                        break;
                }
            }) */ 
        }
        else{
            var invalid_sheet = "";
            this.checkSheetType().invalid_items.forEach((inv, i) =>{
                invalid_sheet += (i+1) + ". "+ inv.FileName + '\n';
            });
            swal('Please correct the following files as they have wrong sheet types: \n' + invalid_sheet);
        }
    }
    else {
        swal('Please provide the required information: Title plan(s) folder and project year');
    }
}

}
