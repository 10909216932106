
import {throwError as observableThrowError,  ConnectableObservable, Observable ,  BehaviorSubject ,  Subject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
//import { rmsapi } from "../../environments/environment";
import { Http, RequestOptions, Headers } from '@angular/http';
import { HttpHeaders, HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Icdiplan } from '../models/icdiplan';
//import { emailPlanObj } from "../models/emailPlanObj";

declare var $: any;

@Injectable()
export class RecordsService {
  private result = new Subject<any>();
  result$ = this.result.asObservable();

  // Data store from Advanced Search
  private _dataSource = new BehaviorSubject([]);
  currentDataSource = this._dataSource.asObservable();

  private _dataSourceFilter = new BehaviorSubject([]);
  currentDataSourceFiltered = this._dataSourceFilter.asObservable();

  // data store from Map
  private _dataSourceFromMap = new BehaviorSubject([]);
  currentDataSourceFromMap = this._dataSourceFromMap.asObservable();

  private _dataSourceFilterFromMap = new BehaviorSubject([]);
  currentDataSourceFilteredFromMap = this._dataSourceFilterFromMap.asObservable();

/*   rmsapi: any = {
    token:"https://rms.doverohio.com/appapi/token",
    url:"https://rms.doverohio.com/appapi/api/rms/",
    apiUser:"https://rms.doverohio.com/appapi/api/user/"
  }; */

/*  rmsapi: any = {
    token:"https://rms.doverohio.com/appapi/token",
    url:"https://demorms.emht.com/appapi/api/rms/",
    apiUser:"https://rms.doverohio.com/appapi/api/user/"
  }; */

/*  rmsapi: any = {
    token:"https://demorms.emht.com/appapi/token",
    url:"https://demorms.emht.com/appapi/api/rms/",  
    apiUser:"https://demorms.emht.com/appapi/api/user/"
  }; */

  rmsapi: any = {
    token:"https://rms.reynoldsburg.gov/token",
    url:"https://rms.reynoldsburg.gov/appapi/api/rms/",
    apiUser:"https://rms.reynoldsburg.gov/appapi/api/user/"  
  }; 

  /*rmsapi: any = {
    token: 'http://localhost:61823/token',
    url: 'http://localhost:61823/api/rms/',
    apiUser: 'http://localhost:61823/api/user/'
  }; */

  apiurl: string = this.rmsapi.url;
  constructor(
    private _http: Http,
    private _httpClient: HttpClient
    ) { }

  changeDataSource(resultSet: any[]) {
    this._dataSource.next(resultSet);
  }

  changeDataSourceFilter(resultSet: any[]) {
    this._dataSourceFilter.next(resultSet);
  }

  changeMapDataSource(resultSet: any[]) {
    this._dataSourceFromMap.next(resultSet);
  }

  changeMapDataSourceFilter(resultSet: any[]){
    this._dataSourceFilterFromMap.next(resultSet);
  }

  getNewResult(resultSet: any){
    this.result.next(resultSet);
  }
  findPlansGallery(searchTerm: string){
   //return this._http.get(this.apiurl + "getsearch/" + searchTerm).map(res => res.json());
   return this._http.get(this.apiurl + 'getsearchbykeywords/' + searchTerm).pipe(map(res => res.json()));
  }

  loadAllPlans(index, size){
    return this._http.get(this.apiurl + 'loadAllPlans/' + index + '/' + size).pipe(map(res => res.json()));
  }

  checkFileOnServer(fieldName: string){
    return this._http.get(this.apiurl + 'checkFileOnServer/' + fieldName).pipe(map(res => res.json()));
  }

  loadAdvancedSearch(advancedSearchParams: any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    let headers = new Headers();
    let formData: FormData = new FormData();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({headers: headers});
    formData.append('advSearchObj', JSON.stringify(advancedSearchParams));
    return this._http.post(this.apiurl + 'getadavancedsearch', formData, options).pipe(map(res => res.json()));
  }

  findPlansByStringQueryBuilder(queryBuilderParams: any, isExplicitSearch: number){
    let headers = new Headers();
    let formData: FormData = new FormData();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({headers: headers});
    formData.append('queryBuilderObj', JSON.stringify(queryBuilderParams));
    return this._http.post(this.apiurl + 'getplansByQueryBuilder/' + isExplicitSearch, formData, options).pipe(map(res => res.json()));
    /* return this._http2.post(this.apiurl + "getplansByQueryBuilder", formData, {
      headers: new HttpHeaders({
        'Content-Type':'application/json'
      })
    }); */
  }

  loadPlansBySet(setID: string){
    let headerOption = new RequestOptions({headers: new Headers({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    })});
    return this._http.get(this.apiurl + 'loadplansBySet/' + setID, headerOption).pipe(map(res => res.json()));
  }

  getPlanSetByMapRef(mapref: string){
    return this._http.get(this.apiurl + 'getplansbyset/' + mapref).pipe(map(res => res.json()));
  }

  saveSheetEdits(saveSheetInfoObj: any){
    let formData: FormData = new FormData();
    formData.append('sheetEditInfo', JSON.stringify(saveSheetInfoObj));

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({headers: headers});
    return this._http.post(this.apiurl + 'saveSheetEdits', formData, options).pipe(
             catchError(error => observableThrowError(error)));

   // return this._http.get(this.apiurl + "saveSheetEdits").map(res => res.json());
  }

  saveSetTitle(filename, title){
    return this._http.get(this.apiurl + 'saveSetTitle/' + filename + '/' + title ).pipe(map(res => res.json()));
  }

  saveSetTitleAndYear(filename, title, year){
    return this._http.get(this.apiurl + 'saveSetTitleAndYear/' + filename + '/' + title + '/' + year ).pipe(map(res => res.json()));
  }

  deleteSheet(filename: string, email: string){
    //console.log(filename + " " + email.split("@")[0]);
    return this._http.get(this.apiurl + 'deleteSheetEdits/' + filename + '/' + email.split('@')[0]).pipe(map(res => res.json()));
  }

  emailPlans(files: any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(files);

    return this._http.post(this.apiurl + 'emailplans/' + files, body, options).pipe(map(res => res.json()));
   }

   getCustomGeneralServerInfo(){
    return this._http.get(this.apiurl + 'getDefaultCustomInfo').pipe(map(res => res.json()));
   }

   checkUser(loginForm){
    return this._http.get(this.apiurl + 'signin/' + loginForm.email + '/' + loginForm.pw).pipe(map(res => res.json()));
   }

   userAuthentication(username, password){
     var data = 'username=' + username + '&password=' + password + '&grant_type=password';

     const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded'
      })
    };

     let headers = new Headers();
     headers.append('Content-Type', 'application/x-www-form-urlencoded');
     var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
     let options = new RequestOptions({headers: headers});
     return this._http.post(this.rmsapi.token, data, options).pipe(map(res => res.json()));
   }

   archivePlanSetList(planSetList){
      let rmsObjArray = [];
      let formData: FormData = new FormData();
      formData.append('folder', planSetList[0].FileSource);
      $.each(planSetList, function (index, val) {
        let rmsObj = {};
        for (var key in val) {
        // console.log(val[key]);
            switch (key.toLocaleLowerCase()) {
                case 'filename':
                  rmsObj[key] = val[key];
                  break;
                case 'filesource':
                  rmsObj[key] = val[key];
                  break;
                case 'planname':
                  rmsObj[key] = val[key];
                  break;
                case 'fileext':
                  rmsObj[key] = val[key];
                  break;
                case 'plantitle':
                  rmsObj[key] = val[key];
                  break;
                case 'planyear':
                  rmsObj[key] = val[key];
                  break;
                case 'setuid':
                  rmsObj[key] = val[key];
                  break;
                case 'sheettype':
                  rmsObj[key] = val[key];
                  break;
                case 'sheetnumber':
                  rmsObj[key] = val[key];
                  break;
                case 'sheetstreets':
                  rmsObj[key] = val[key];
                  break;
                case 'hassanitary':
                  rmsObj[key] = val[key] == 'YES' ? 1 : 0;
                  break;
                case 'hasstorm':
                  rmsObj[key] = val[key] == 'YES' ? 1 : 0;
                  break;
                case 'haswater':
                  rmsObj[key] = val[key] == 'YES' ? 1 : 0;
                  break;
                case 'hasswmp':
                  rmsObj[key] = val[key] == 'YES' ? 1 : 0;
                  break;
                case 'hasbridge':
                  rmsObj[key] = val[key] == 'YES' ? 1 : 0;
                  break;
                case 'constructionplan':
                    formData.append('uploadFile', val[key]);
                    break;
            }
        }
        rmsObjArray.push(rmsObj);
    });

    formData.append('archiveInfo', JSON.stringify(rmsObjArray));
    // let headers = new Headers();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    // let options = new RequestOptions({headers: headers});
    return this._httpClient.post(this.apiurl + 'ArchivePlans', formData, {
      headers: headers,
      reportProgress: true,
      observe: "events"
    })
   }

   SharePlans(PlansEmailObj: any){
    let _emailDetails = {
        emailSubject: PlansEmailObj.subject,
        emailFrom : PlansEmailObj.emailFrom,
        emailTo: PlansEmailObj.emailTo,
        message: PlansEmailObj.message,
        plansArray: PlansEmailObj.plansArray
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
        })
      };

    let headers = new Headers();
    let formData: FormData = new FormData();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({headers: headers});
    formData.append('emailDetailsObj', JSON.stringify(_emailDetails));
      return this._http.post(this.apiurl + 'shareplans', formData, options).pipe(map(res => res.json()));
   }

   InitSearchCriteriaTrail(){
      return this._http.get(this.apiurl + 'initsearchcriteriatrail').pipe(map(res => res.json()));
   }

}

