import { Component, OnInit } from '@angular/core';
import { RmsService } from '../services/rms.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private _rmsService:RmsService) { }

  ngOnInit() {
    this.checkUserLogin();
  }

  checkUserLogin(){
    this._rmsService.updateUserSecurity(true, 'GIS-support@reynoldsburg.gov',3);  // DMM 10/11/2022

/*    if(localStorage.getItem('currentUser')){
      let _userInfo = JSON.parse(localStorage.getItem('currentUser'));
      this._rmsService.updateUserSecurity(true, _userInfo.email, _userInfo.al);
    } */
    
  }
}
