import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RecordsService } from "../services/records.service";
import { RmsFilterObj } from '../models/rmsFilterObj';
import { RmsService } from '../services/rms.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Output() sharedFilterChange = new EventEmitter();
  advFilter:RmsFilterObj;
  isSearching:boolean = false;
  isSearchComplete:boolean = false;
  searcTerm:string = "";
  sharedSetTitle:string = "";
  resultset: any[] = [];
  constructor(
    private _recordService:RmsService,
    private _rmsService:RecordsService) { }

  ngOnInit() {
    this.advFilter = {
      name: "",
      year:"",
      title:"",
      street:""
    }
    if(this._recordService.searchResutlsShared.resultArray.length > 0){
      this.searcTerm = this._recordService.searchResutlsShared.searchTerm;
      this.resultset = this._recordService.searchResutlsShared.resultArray;
    }
  }
  change(newValue) {
    this.sharedFilterChange.emit(this.advFilter);
  }

  findPlans(evt){
    let searchTerm = evt.target.value;
    if(evt.key.toLowerCase() == "enter"){
      if(searchTerm){
        let cleanInputValue = searchTerm.replace(/[^\w\s]/gi, '');
        //console.log(cleanInputValue);
        this.isSearchComplete = false;
        this.isSearching = true;
        this._rmsService.findPlansGallery(cleanInputValue).subscribe(res =>{
         // console.log(res);
          if(res === "No records found"){
            this.resultset = [];
          }
          else{
            this.resultset = res;
            this._recordService.searchResutlsShared.searchTerm = this.searcTerm;
            this._recordService.searchResutlsShared.resultArray = this.resultset;
          }
            
          this.isSearching = false;
          this.isSearchComplete = true;
        })
      } 
    }
    
  }

  resetForm(){
    this.searcTerm = "";
    this.resultset = [];
    this._recordService.searchResutlsShared.searchTerm = "";
    this._recordService.searchResutlsShared.resultArray = this.resultset;
    this.isSearching = false;
    this.isSearchComplete = false;
  }

}
