
import {map, retry, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CurrentUser2 } from '../models/current-user2';
import { rmsPlanObj } from '../models/rmsPlanObj';
import { PlanObj } from '../models/planObj';
import { Observable ,  of ,  BehaviorSubject ,  Subject } from 'rxjs';
import { Http, RequestOptions, Headers  } from '@angular/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

declare var $: any;
@Injectable()
export class RmsService {

/*   rmsapi: any = {
    token:"https://rms.doverohio.com/appapi/token",
    url:"https://rms.doverohio.com/appapi/api/rms/",
    apiUser:"https://rms.doverohio.com/appapi/api/user/"
  }; */

/*  rmsapi: any = {
    token:"https://rms.doverohio.com/appapi/token",
    url:"https://demorms.emht.com/appapi/api/rms/",
    apiUser:"https://rms.doverohio.com/appapi/api/user/"
  }; */

  rmsapi: any = {
    token:"https://rms.reynoldsburg.gov/token",
    url:"https://rms.reynoldsburg.gov/appapi/api/rms/",
    apiUser:"https://rms.reynoldsburg.gov/appapi/api/user/"  
  }; 

/*  rmsapi: any = {
    token:"https://demorms.emht.com/appapi/token",
    url:"https://demorms.emht.com/appapi/api/rms/",
    apiUser:"https://demorms.emht.com/appapi/api/user/"  
  };  */

/*  rmsapi: any = {
    token: 'http://localhost:61823/token',
    url: 'http://localhost:61823/api/rms/',
    apiUser: 'http://localhost:61823/api/user/'
  }; */

  apiurl: string = this.rmsapi.url;
  apitoken: string = this.rmsapi.token;
  apiuser: string = this.rmsapi.apiUser;
  plansShared: any = [];
  profile$: Subject<any> = new BehaviorSubject<any>({});
  AccessLevel$: Subject<number> = new BehaviorSubject<number>(1);
  rmsUser = new BehaviorSubject([]);
  isUserConnected = false;
  searchResutls: any = [];
  searchResutlsShared: any = {
    searchTerm: '',
    resultArray: []
  };
  searchResutlsSharedAdvance: any = {
    searchName: '',
    searchTitle: '',
    searchYear: '',
    searchStreets: '',
    queryBuilderDisplay: [],
    resultArray: [],
    dtblSearchCriteriaTrail: [],
    queryStringBuilder: []
  };
  currentUser: CurrentUser2;
  searchResultSet: any = [];
  results: any[] = [];
  constructor(private _http: Http, private _httpClient: HttpClient, private _router: Router) { }
  emit(value: any) {
    this.profile$.next(value);
  }
  emitAL(value: number) {
    this.AccessLevel$.next(value);
  }

  get profile(): BehaviorSubject<any> {
    return this.profile$ as BehaviorSubject<any>;
  }
  get AccessLevel(): BehaviorSubject<number>{
    return this.AccessLevel$ as BehaviorSubject<number>;
  }

  initCredentials(user: any) {
    if (user) {
      this.currentUser.IsUser = user.isUser;
      this.currentUser.AccessLevel = user.al;
      this.currentUser.Email = user.email;
    }else {
      this.currentUser.IsUser = false;
      this.currentUser.AccessLevel = 0;
      this.currentUser.Email = null;
    }
  }

  getResults(): Observable<any[]> {
    return of(this.results);
  }

  setResults(data: any[]) {
    this.results = [];
    this.results = data;
  }

  shareItem(elmt, plan) {
    const that = this;
    const btnParentDiv = $($(elmt.target)[0]).parents()[3];
   const offset = $(btnParentDiv).offset();
    $(btnParentDiv).clone().addClass('product-clone').css({
      'left' : offset.left + 'px',
      'top' : (offset.top - $(window).scrollTop()) + 'px',
      'width' :  $(btnParentDiv).width() + 'px',
      'height' : $(btnParentDiv).height() + 'px'
    }).appendTo($('.product').parent());
    const cart = $('.shoping-cart').offset();
    // tslint:disable-next-line: max-line-length
    $('.product-clone').animate( { top: (cart.top - $(window).scrollTop()) + 'px', left: cart.left + 'px', 'height': '0px', 'width': '0px' }, 800, function(){
      $(this).remove();
      if (that.plansShared.length > 0) {
        for (let i = 0; i < that.plansShared.length; i++) {
          if (that.plansShared[i].FILENAME === plan.FILENAME) {
            that.plansShared.splice(i, 1);
          }
        }
      }
      that.plansShared.push(plan);
  });
}

setSearchResultSet(data: any): void {
  this.searchResutls = [];
  this.searchResutls.push(data);
}

addResultSet(result: rmsPlanObj[]): void {
  this.searchResutls = [];
  this.searchResutls = result;
}

getSearchResultSet() {
  return this.searchResutls;
}

checkUser(email: string, pw: string) {
    return this.getUserToken(email, pw);
  }

getUserBySignIn(email, pw) {
/*   const data = 'username=' + email + '&password=' + pw + '&grant_type=password';
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  const options = new RequestOptions({https: headers});
 */
  const body = JSON.stringify({Email: email, Password: pw});
  const headers = new Headers({ 'Content-Type': 'application/json' });

  const requestOptions = new RequestOptions({
      headers: headers,
      body: body
  });
  return this._http.get('https://localhost:44331/api/user', requestOptions);
}

getUserToken(email, pw) {
  const that = this;
  const _promise = new Promise((resolve, reject) => {
    const data = 'username=' + email + '&password=' + pw + '&grant_type=password';
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({headers: headers});
    that._http.post(that.apitoken, data, options)
          .subscribe((token: any) => {
            // console.log('The API Token: ', token);
            if (token.status === 200) {
              const _token = JSON.parse(token['_body']).access_token;
              localStorage.setItem('user_token', _token);
              that.getUserClaims().subscribe(res => {
                const userClaim = JSON.parse(res['_body']);
                const _user = {
                  IsUser: true,
                  FirstName: userClaim.UFName,
                  LastName: userClaim.ULName,
                  Email: userClaim.Email,
                  AccessLevel: userClaim.AccessLevel
                };
                const _al = userClaim.AccessLevel;
                that.emit({user: _user});
                that.emitAL(_al);
                resolve(_user);
              });
            } else {
              resolve(null);
            }
          }, (err) => {
            resolve(err);
          });
  });

  return _promise;
}

getUserClaims() {
  const hdr = {headers: new Headers({'Authorization': 'Bearer ' + localStorage.getItem('user_token')})};
  return this._http.get(this.apiuser + 'getUserClaims', hdr);
}

updateUserSecurity(isUser: boolean, email: string, al: number) {
  this.isUserConnected = true;
  const userArray = [isUser, email, al];
  const user = userArray;
  this.rmsUser.next(user);
}

signOut() {
  this.isUserConnected = false;
  const userArray = [];
  const user = userArray;
  this.rmsUser.next(user);
  if (localStorage.getItem('currentUser')) {
    localStorage.removeItem('currentUser');
  }
}

}
