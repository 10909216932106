import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RmsUser } from '../models/rmsUser';

@Injectable()
export class UserService {
  private _user = new BehaviorSubject<RmsUser>({email:null, al:0, isUser:false});
  cast = this._user.asObservable();
  constructor() { }
  setUser(user:RmsUser){
    this._user.next(user);
  }
}
