import { Component, OnInit } from '@angular/core';
import { RecordsService } from '../../services/records.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-result-map',
  templateUrl: './result-map.component.html',
  styleUrls: ['./result-map.component.css']
})
export class ResultMapComponent implements OnInit {
  
  isResult:boolean = false;
  isDataFiltered:boolean = true;
  data:Array<any> = [];
  data_filtered:Array<any> = [];
  total_records: number = 0;
  
  filteredCount = {count: 0};

  finished = false;
  isLoading = false;
  
  mapref:string;
  constructor(
    private _recordsService: RecordsService,
    private _router: Router
  ) { }

  ngOnInit() {
    let url = this._router.url;
    var url_part = url.split("/");
    this.mapref = url_part[url_part.length -1];


    this._recordsService.currentDataSourceFromMap.subscribe(res =>{
      this.data = res;
      this.data_filtered = res;
      this.filteredCount.count = this.data.length;
      this.total_records = res.length;

      if(res.length > 0) this.isResult = true
      else this.isResult = false;

    });
  }
  updatePlanType(plantype:string){
    this.data_filtered = this.data;
    switch (plantype) {
      case 'sanitary':
       this.data_filtered = this.data_filtered.filter((plan) => plan.HasSanitary == 1);
       this.filteredCount.count = this.data_filtered.length;
       this._recordsService.changeMapDataSourceFilter(this.data_filtered);
       break;
     case 'water':
       this.data_filtered = this.data_filtered.filter((plan) => plan.HasWater == 1);
       this.filteredCount.count = this.data_filtered.length;
       this._recordsService.changeMapDataSourceFilter(this.data_filtered);
       break;
     case 'swmp':
       this.data_filtered = this.data_filtered.filter((plan) => plan.HasSWMP == 1);
       this.filteredCount.count = this.data_filtered.length;
       this._recordsService.changeMapDataSourceFilter(this.data_filtered);
       break;
     case 'bridge':
       this.data_filtered = this.data_filtered.filter((plan) => plan.HasBridge == 1);
       this.filteredCount.count = this.data_filtered.length;
       this._recordsService.changeMapDataSourceFilter(this.data_filtered);
       break;
     case 'storm':
       this.data_filtered = this.data_filtered.filter((plan) => plan.HasStorm == 1);
       this.filteredCount.count = this.data_filtered.length;
       this._recordsService.changeMapDataSourceFilter(this.data_filtered);
       break;
       
     default: // all
       this._recordsService.changeMapDataSourceFilter(this.data);
       this.filteredCount.count = this.data.length;
       break;
    }
  }

  scrollToTop(){
    $('html, body').animate({scrollTop : 0},900);
  }

}
