import { Component, OnInit } from '@angular/core';
import { RmsService } from "../services/rms.service";
import { AuthenticationService } from '../services/authentication.service';
import { RecordsService } from '../services/records.service';
import { CurrentUser2 } from '../models/current-user2';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

declare var $:any;
declare var swal:any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isSending:boolean = false;
  isBrowserIE: boolean = false;
  formData: any;

  user: any;
  rmsUser:CurrentUser2;
  emailSender: string;
  subject: string;
  receivers: string;
  shareMessage: string;
  constructor(
    public _rmsService: RmsService,
    private _recordService: RecordsService,
    public _auth:AuthenticationService,
    private router:Router, private _userService:UserService) {}

  ngOnInit() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.checkuserBrowser();
    this.initUser();
  }

  checkuserBrowser(){
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if(isIEOrEdge) this.isBrowserIE = true;
    else this.isBrowserIE = false;
  }
  initUser(){
    this.user = {   //DMM 10/11/2022 Security
      IsUser: true,
      FirstName: "GIS",
      LastName:"Support",
      Email:"GIS-support@reynoldsburg.gov",
      AccessLevel:3
    };
    let _al = this.user.AccessLevel;
    this._rmsService.emitAL(_al);

/*    this._rmsService.profile.subscribe(profile =>{
      this.user = profile.user;
      //if(this.user) this._rmsService.AccessLevel = this.user.AccessLevel;
    })
    this.user = {
      IsUser: false,
      FirstName: "",
      LastName:"",
      Email:"",
      AccessLevel:0
    };
    if(localStorage.getItem('user_token')){
      this._rmsService.getUserClaims().subscribe(res =>{
        let userClaim = JSON.parse(res["_body"]);
        this.user = {
          IsUser: true,
          FirstName: userClaim.UFName,
          LastName:userClaim.ULName,
          Email:userClaim.Email,
          AccessLevel:userClaim.AccessLevel
        };
        let _al = userClaim.AccessLevel;
        this._rmsService.emitAL(_al);
      })
    } */
  }

  signout(){
    localStorage.removeItem("user_token");
    this.user = {
      IsUser: false,
      FirstName: "",
      LastName:"",
      Email:"",
      AccessLevel:0
    };
    this.router.navigate(['/login']);
  }

  shareItems(){
    if(this._rmsService.plansShared.length > 0){
      $('#plan-share-modal').modal({
        keyboard: false,
        backdrop:'static'
      });
    }else{
      swal("Your cart is empty");
    }
  }

  onSubmit(formData) {
    if(formData.valid) {
      $("#btn_submit").prop("disable", true);
      this.isSending = true;
      var message = {
          subject:formData.value.emailSubject,
          emailFrom:formData.value.emailSender,
          emailTo: formData.value.emailReceivers,
          message: formData.value.emailMessage,
          plansArray:this._rmsService.plansShared,
          timestamp: Date.now()
        };
      this._recordService.SharePlans(message)
      .subscribe(res =>{
        $('#plan-share-modal').modal('hide');
        if(res === "OK"){
          this._rmsService.plansShared = [];
          swal("Files sent successfully. A receipt is being emailed to you regarding this  transaction.");
        }
        else{
//          console.log(res);
          swal("Ooops, something went wrong. Please try again later. | Potential Error ->: " + res);
        }

        $("#btn_submit").prop("disable", false);
        this.isSending = false;
      })

    }

}

removePlanFromCart(index){
  this._rmsService.plansShared.splice(index, 1);
  if(this._rmsService.plansShared.length == 0){
    $('#plan-share-modal').modal('hide');
  }
}
}
