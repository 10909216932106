import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RmsService } from '../services/rms.service';
import { RecordsService } from '../services/records.service';
import * as _ from 'lodash';

declare var $:any;
//declare var swal:any;

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {
  isResult:boolean = false;
  isDataFiltered:boolean = true;
  data:Array<any> = [];
  data_filtered:Array<any> = [];
  total_records: number = 0;
  
  filteredCount = {count: 0};

  finished = false;
  isLoading = false;

  noThumbnail:string = "../../assets/img/no-image-available.png";

  constructor( 
    public _rmsService: RmsService,
    private _recordsService: RecordsService, 
    ) {}

  ngOnInit() {

    this._recordsService.currentDataSource.subscribe(res =>{
      this.data = res;
      this.data_filtered = res;
      this.filteredCount.count = this.data.length;
      this.total_records = res.length;

      if(res.length > 0) this.isResult = true
      else this.isResult = false;
    });
    $(function () {
      //$('[data-toggle="tooltip"]').tooltip();
    })
    $(window).scroll(function(){
     if ($(this).scrollTop() > 200){
       $(".btn-scroll-top").show();
     }else{
       $(".btn-scroll-top").hide();
     }
   });
  }

  updatePlanType(plantype:string){
    this.data_filtered = this.data;
    switch (plantype) {
      case 'sanitary':
       this.data_filtered = this.data_filtered.filter((plan) => plan.HasSanitary == 1);
       this.filteredCount.count = this.data_filtered.length;
       this._recordsService.changeDataSourceFilter(this.data_filtered);
       break;
     case 'water':
       this.data_filtered = this.data_filtered.filter((plan) => plan.HasWater == 1);
       this.filteredCount.count = this.data_filtered.length;
       this._recordsService.changeDataSourceFilter(this.data_filtered);
       break;
     case 'swmp':
       this.data_filtered = this.data_filtered.filter((plan) => plan.HasSWMP == 1);
       this.filteredCount.count = this.data_filtered.length;
       this._recordsService.changeDataSourceFilter(this.data_filtered);
       break;
     case 'bridge':
       this.data_filtered = this.data_filtered.filter((plan) => plan.HasBridge == 1);
       this.filteredCount.count = this.data_filtered.length;
       this._recordsService.changeDataSourceFilter(this.data_filtered);
       break;
     case 'storm':
       this.data_filtered = this.data_filtered.filter((plan) => plan.HasStorm == 1);
       this.filteredCount.count = this.data_filtered.length;
       this._recordsService.changeDataSourceFilter(this.data_filtered);
       break;
       
     default: // all
       this._recordsService.changeDataSourceFilter(this.data);
       this.filteredCount.count = this.data.length;
       break;
    }
  }

  scrollToTop(){
    $('html, body').animate({scrollTop : 0},900);
  }
}
