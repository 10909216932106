import { Pipe, PipeTransform } from '@angular/core';
import { RmsFilterObj } from '../models/rmsFilterObj';

@Pipe({
  name: 'advancedFilter'
})
export class AdvancedFilterPipe implements PipeTransform {

  transform(results: any, args?: any[]): any {
    return results.filter(res =>{
      if(args[0] !== "") // year
          return res.FileName.toLowerCase().includes(args[0].toLowerCase());
      if(args[1] !== "")
          return res.PlanYear.includes(args[1]);
      if(args[2] !== "")
          return res.PlanTitle.toLowerCase().includes(args[2].toLowerCase()); 

      if(args[3] !== "")
          return res.SheetStreets.toLowerCase().includes(args[3].toLowerCase()); 

      if(args[0] === "") // year
          return results;
      if(args[1] === "")
          return results;
      if(args[2] === "")
          return results;
      if(args[3] === "")
          return results;  
    })
  }
}
